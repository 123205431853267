import { writable } from 'svelte/store';

export const highContrastStore = writable<boolean>(false);
export const highContrastSetManually = writable<boolean>(false);

let setManually = false;
highContrastSetManually.subscribe((bool) => {
  setManually = bool;
});

let useHighContrast = false;
highContrastStore.subscribe((bool) => {
  useHighContrast = bool;
});

export function setHighContrastIfNotManuallySet(bool: boolean): void {
  if (!setManually) {
    highContrastStore.set(bool);
  }
}

export function toggleHighContrastStore(): void {
  highContrastStore.set(!useHighContrast);
}
